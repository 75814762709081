import React, {useRef, useEffect} from 'react';
import styled from 'styled-components'
import gsap from 'gsap'

// const InputWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   align-items: center;
//   flex-direction: column;
//   position: relative;
//   &:not(:last-child){
//     margin-bottom: 1rem !important;
//   }
// `

// const StyledInput = styled.input`
//   position: relative !important;
//   padding: .5rem 1rem !important; 
//   background-color:  #2F80ED !important;
//   height: 2rem !important;
//   box-sizing:border-box !important;
//   border-bottom: none !important;
//   border-radius: 50rem !important;
//   margin-bottom: .5rem !important;

//   color: #fff;

//   &::placeholder {
//     color: #fff !important;
//     font-size: .75rem;
    
//   }
// `

// const Error = styled.div`
//   color: #F44336;
//   font-size: .7rem;
//   position: absolute;
//   left: 0;
//   bottom: -20px;
// `

const Input = ({field, form:{touched, errors}, ...props}) => {
  let errorEl = useRef(null)

  useEffect(() => {
    (errors[field.name] && touched[field.name]) && gsap.from(errorEl, {duration: 1.2, y: -20, opacity: 0, ease: "back"})

  },)

  return (
  <div className="input-wrapper">   
    <input className="styled-input" {...field} {...props}>{props.children}</input>
    <div className="input-error" ref={el => errorEl = el} >{errors[field.name]}</div>
  </div>
  )
};

export default Input;